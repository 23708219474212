import { graphql, Link, StaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { Link as ScrollLink } from "react-scroll";
import superchargeMsp from "../assets/images/roadshow.svg";
import superSummit24 from "../assets/images/superSubmit2024.svg";
import bgvideo from "../assets/videos/Bg(1).mp4";
import CustomAccordion from "../components/common/accordian";
import Buttons from "../components/common/button";
import CarouselComponent from "../components/common/carousel-component";
import FormBuilder from "../components/common/FormBuilder/Form-Builder";
import handleFormSubmit from "../components/common/FormBuilder/Form-Function";
import Frame from "../components/common/frame";
import SVGIcon from "../components/common/SVGIcon";
import Navigation from "../components/navigation";
import "../styles/component/faq.scss";
import "../styles/component/pricing-faq.scss";
import "../styles/pages/Supersummit-2022.scss";
import "../styles/pages/supersummit-2023.scss";
import "../styles/pages/supersummit-2024.scss";
import "../styles/pages/dallas-2023.scss";
import "../styles/component/popup.scss";
import "../styles/component/forms.scss";
import Laugh from "./../assets/images/laugh.svg";
import Popper from "./../assets/images/party-popper.svg";
import YoutubePlayer from "../components/common/Youtube-player";
import Fade from "react-reveal/Fade";

const SuperSummitUSA = () => {
  const [popUp, setPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const loadRef = useRef();

  loadRef.current = setLoading;
  function showpopup() {
    setPopUp(true);
  }
  // useEffect(() => {
  //   setTimeout(showpopup, 3000);
  // }, []);
  //   useEffect(() => {
  //   const anime=lottie.loadAnimation({
  //     container: document.querySelector(".text-center-anime"),
  //     loop: true,
  //     autoplay: true,
  //     renderer: "svg",
  //     animationData: beeranimate,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //    },
  //   })
  //   return () => anime.destroy()
  // })

  const responsiveSpeaker = {
    mobile2: {
      breakpoint: { max: 535, min: 480 },
      items: 1.5,
    },
    mobile3: {
      breakpoint: { max: 480, min: 460 },
      items: 1.4,
    },
    mobile5: {
      breakpoint: { max: 460, min: 435 },
      items: 1.35,
    },
    mobile4: {
      breakpoint: { max: 435, min: 0 },
      items: 1,
    },
  };

  const responsive = {
    desktopLarge: {
      breakpoint: { max: 4000, min: 1200 },
      items: 2.8,
    },
    desktopSmall: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },

    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 578, min: 535 },
      items: 1.5,
    },

    mobile2: {
      breakpoint: { max: 535, min: 480 },
      items: 1.4,
    },
    mobile3: {
      breakpoint: { max: 480, min: 460 },
      items: 1.3,
    },
    mobile5: {
      breakpoint: { max: 460, min: 435 },
      items: 1.35,
    },
    mobile4: {
      breakpoint: { max: 435, min: 0 },
      items: 1.2,
    },
  };

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
      required: false,
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "roadshow_cities",
      type: "select",
      choices: [
        "Tampa, FL • May 31, 2024",
        "Dallas, TX • Aug 16, 2024",
        "Kelowna, BC • Sep 8, 2024",
        "Hanover, MD • Sep 11, 2024",
        "Los Angeles, CA • Nov 8, 2024",
        "Tampa, FL • Dec 13, 2024",
        
      ],
      data: {
        "Tampa, FL • May 31, 2024": "Tampa May 2024",
        "Dallas, TX • Aug 16, 2024": "Dallas 2024",
        "Kelowna, BC • Sep 8, 2024": "Kelowna 2024",
        "Hanover, MD • Sep 11, 2024": "Hanover 2024",
        "Los Angeles, CA • Nov 8, 2024": "Los Angeles 2024",
        "Tampa, FL • Dec 13, 2024": "Tampa December 2024",
        
      },
      label: "Category",
      errorMessage: "Please select atleast one city",
    },
  ];

  const CountryInfo = ({ card, id }) => {
    return (
      <div className="country-card text-center">
        <img src={card.image[id].url} />
        <p className="number font-roboto fw-bold text-center">
          {"0" + (id + 1)}
        </p>
        <p className="p24 font-roboto text-center font-white m-0">
          {card.heading[id]}
        </p>
        <p className="p12 text-center place mb10">{card.title[id]}</p>
        <p className="p14 text-center text-pink m-0">{card.text[id]}{" "}{card.subtext[id]}</p>
      </div>
    );
  };

  async function handleSubmit(items) {
    const formData = items;
    setLoading(true);
    sessionStorage.setItem("superSummitName", items[0].value);
    sessionStorage.setItem("superSummitEmail", items[1].value);
    var successval = handleFormSubmit(
      formData,
      process.env.HUBSPOT_DALLAS_SUPERSUMMIT_2023_ENDPOINT,
      window.location.href,
      "Supersummit USA - Superops",
      null,
      null,
      "success"
      // `success?refererName=${items[0].value}&&refererEmail=${items[1].value}`,
    );
    // successval === true && setSuccess(successval);
  }

  // async function handleSubmit(items) {
  //   let x = await formPromise(items).then(
  //     window.location = `/success?refererName=${items[0].value}&&refererEmail=${items[1].value}`
  //   )
  // }

  const PopUpForm = () => {
    return (
      <div className="popup-signup">
        <div class="wrap position-fixed"></div>
        <Container className="position-fixed">
          <div className="Form-box position-relative">
            <SVGIcon
              name="close-icon-black"
              className="close-icon"
              onClick={() => setPopUp(false)}
            />
            {!success ? (
              <>
                <FormBuilder
                  formClassName="forms"
                  errorClassName="error-message"
                  inputErrorClassName="error-active"
                  data={formdata}
                  formmessage="Enter your details to register!"
                  buttonText={
                    loading ? (
                      <div className="loading-anim">
                        LOADING
                        <span className="ellipsis-anim">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </span>
                      </div>
                    ) : (
                      "SUBMIT"
                    )
                  }
                  buttonClassName="primary pink form-btn"
                  onSubmit={(items) => handleSubmit(items)}
                  IpStackData
                />
                <div className="disclaimer a8">
                  <p className="p8">
                    By clicking "SUBMIT", you agree to SuperOps's{" "}
                    <a href="https://superops.com/terms" target="_blank">
                      Terms of use
                    </a>{" "}
                    and{" "}
                    <a href="https://superops.com/privacy" target="_blank">
                      Privacy policy
                    </a>
                    .
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="success-box text-center">
                  <img src={Popper} alt="popper" />

                  <p className="ty">
                    Thanks for subscribing! <br /> We'll keep you posted.
                  </p>

                  <div className="p16">
                    <p>
                      {" "}
                      Before you go, why don't you take a look at our{" "}
                      {/* <span>
                        <img src={Laugh} alt="laugh" />
                      </span>{" "} */}
                      <br />{" "}
                      <a href="https://supersummit.com/jan-2022/">
                        on-demand virtual summit?
                      </a>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    );
  };

  return (
    <div>
      <StaticQuery
        query={graphql`
          query SuperSummitUSA {
            SuperOps {
              pageComponents(where: { pageName: "SuperSummit - USA" }) {
                hero {
                  heading {
                    text
                  }
                  heroExcerptList
                  heroImage {
                    url
                  }
                }
                seo {
                  keywords
                  description
                  title
                  image {
                    id
                    url
                  }
                }
                sectionName
                pageContents {
                  heading
                  subtext {
                    html
                  }
                  detailIcon {
                    url
                  }
                  detailContent
                  detailHeading
                }
                timeline {
                  time
                  agenda
                  title
                  speakerName
                  speakerTitle
                  speaker {
                    url
                  }
                }
                links {
                  linkName
                  icon {
                    url
                  }
                  authorName
                }
                faqs {
                  category
                  question
                  answerrt {
                    html
                  }
                }
                cards {
                  cardName
                  cardDescription
                  heading
                  content {
                    html
                  }
                  title
                  text
                  subtext
                  image {
                    url
                  }
                  links
                }
                images {
                  url
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            {data.SuperOps.pageComponents.map((page) => {
              const { hero, seo, faqs, cards } = page;
              return (
                <div className="super-summit-24 Supersummit-2022 Supersummit-2023 dallas-2023 ">
                  <Frame
                    seo={seo}
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                    twitterOG
                  >
                    {popUp && <PopUpForm />}

                    <header>
                      <Navigation
                        page="Supersummit-2022"
                        tabs={[
                          { name: "why attend", id: "1" },
                          { name: "faq", id: "3" },
                        ]}
                        color={"transparent"}
                        setPopUp={setPopUp}
                      />
                    </header>

                    <section className="Hero position-relative">
                      <Container>
                        <Col
                          lg={10}
                          className="d-flex mx-auto gap-70 flex-wrap"
                        >
                          <div className="hero-img-wrapper w-100 down-to-up-1">
                            <img src={superSummit24} className="w-100" alt="" />
                          </div>
                          <div>
                            <h1 className="heading font-roboto font-white fw-600 mb30 down-to-up-1">
                              {hero.heading.text}
                            </h1>
                            <div className="d-flex gap-8 flex-wrap">
                              {hero.heroExcerptList.map((item, id) => {
                                return (
                                  <div className="text-pink d-flex gap-8 align-items-center down-to-up-2">
                                    <p className="fw-600 m-0 p20">{item}</p>
                                    {hero.heroExcerptList.length - 1 !== id
                                      ? "|"
                                      : ""}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Col>
                      </Container>
                    </section>

                    <section className="country-map">
                      <Container className="position-relative">
                        <img
                          src={hero.heroImage.url}
                          className="w-100 down-to-up-2"
                        />
                        <Col
                          lg={12}
                          className="position-absolute map-wrapper mx-auto"
                        >
                          <Fade bottom distance="20px">
                            <div className="d-flex justify-content-center gap-24 flex-wrap countries w-100 d-dsk">
                              {cards[0].heading.map((item, id) => {
                                if (cards[0].links[id] === "-")
                                  return (
                                    <CountryInfo card={cards[0]} id={id} />
                                  );
                                else
                                  return (
                                    <Link
                                      to={cards[0].links[id]}
                                      className="country-card"
                                    >
                                      <CountryInfo card={cards[0]} id={id} />
                                    </Link>
                                  );
                              })}
                            </div>
                          </Fade>
                          <Fade bottom distance="20px">
                            <Buttons
                              theme="primary pink w-100 regis-btn d-flex mx-auto d-dsk"
                              text="REGISTER NOW FOR FREE"
                              onClick={() => setPopUp(true)}
                            />
                          </Fade>
                        </Col>
                        <Row className="d-mob mb100 text-center countries mtm100">
                          <Fade bottom distance="20px">
                            <CarouselComponent
                              status
                              swipeable
                              responsive={responsiveSpeaker}
                              autoPlay={false}
                            >
                              {cards[0].heading.map((item, id) => {
                                if (cards[0].links[id] === "-")
                                  return (
                                    <CountryInfo card={cards[0]} id={id} />
                                  );
                                else
                                  return (
                                    <Link to={cards[0].links[id]}>
                                      <CountryInfo card={cards[0]} id={id} />
                                    </Link>
                                  );
                              })}
                            </CarouselComponent>
                          </Fade>
                        </Row>
                        <Fade bottom distance="20px">
                          <Buttons
                            theme="primary pink w-100 regis-btn d-flex mx-auto d-mob"
                            text="REGISTER NOW FOR FREE"
                            onClick={() => setPopUp(true)}
                          />
                        </Fade>
                      </Container>
                    </section>

                    <section className="meet-and-hear">
                      <Container>
                        <Col lg={10} className="mx-auto">
                          <Fade bottom distance="20px">
                            <h2 className="font-roboto text-white fw-600 text-center m-0">
                              {cards[1].cardName}
                            </h2>
                          </Fade>
                          <Fade bottom distance="20px">
                            <div className="d-flex gap-24 justify-content-center mt40 d-dsk">
                              {cards[1].heading.map((item, id) => {
                                return (
                                  <div className="card-wrapper">
                                    <div className="img-wrapper">
                                      <img
                                        src={cards[1].image[id].url}
                                        className="w-100"
                                      />
                                    </div>
                                    <p className="p28 text-white fw-600 m-0 mt40">
                                      {cards[1].heading[id]}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </Fade>
                          <Row className="d-mob mb100 mt40">
                            <Fade bottom distance="20px">
                              <CarouselComponent
                                status
                                swipeable
                                responsive={responsiveSpeaker}
                                autoPlay={false}
                              >
                                {cards[1].heading.map((item, id) => {
                                  return (
                                    <div className="card-wrapper">
                                      <div className="img-wrapper">
                                        <img
                                          src={cards[1].image[id].url}
                                          className="w-100"
                                        />
                                      </div>
                                      <p className="p28 text-white fw-600 m-0 mt40">
                                        {cards[1].heading[id]}
                                      </p>
                                    </div>
                                  );
                                })}
                              </CarouselComponent>
                            </Fade>
                          </Row>
                        </Col>
                      </Container>
                    </section>

                    <section className="event">
                      <Container>
                        <Col lg={10} className="mx-auto">
                          <div className="prelative video-wrapper d-mob">
                            <Fade bottom distance="20px">
                              <h3 className="mt50 mb24 video-title mx-auto plr24">
                                Highlights from our last meetup
                              </h3>
                            </Fade>
                            <Fade bottom distance="20px">
                              <YoutubePlayer
                                red
                                fullscreenCover
                                className="pulsating-circle-mob"
                                videoScreenshot={{
                                  url:
                                    "https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/mVQPUc3QtujBZcMG8CRw",
                                }}
                                videoURL="https://www.youtube.com/embed/VS7h8pPu7wc"
                              />
                            </Fade>
                          </div>
                          <Fade bottom distance="20px">
                            <div className="prelative video-wrapper d-dsk">
                              <YoutubePlayer
                                fullscreenCover
                                red
                                title={"Highlights from our last meetup"}
                                videoScreenshot={{
                                  url:
                                    "https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/mVQPUc3QtujBZcMG8CRw",
                                }}
                                videoURL="https://www.youtube.com/embed/VS7h8pPu7wc"
                              />
                            </div>
                          </Fade>
                        </Col>
                      </Container>
                      <div id="1"></div>
                    </section>

                    <section className="why-attend">
                      <Container>
                        <Row className="justify-content-center">
                          <Col lg={10}>
                            <Fade bottom distance="20px">
                              <h1 className="font-roboto font-white fw-600 mb30">
                                {cards[2].cardName}
                              </h1>
                            </Fade>
                          </Col>

                          <Row className="justify-content-center">
                            <Col lg={4} className="font-white content">
                              <Fade bottom distance="20px">
                                {parse(cards[2].content[0].html)}
                              </Fade>
                            </Col>
                            <Col lg={6} className="d-dsk">
                              <Fade bottom distance="20px">
                                <Row className="justify-content-end gap-24">
                                  {cards[2].text.map((item, id) => {
                                    return (
                                      <div
                                        className={`card-wrapper position-relative ${
                                          id < 2 ? "open-up" : "open-down"
                                        }`}
                                      >
                                        <div className="img-wrapper mb30">
                                          <img
                                            src={cards[2].image[id].url}
                                            className="w-100"
                                          />
                                        </div>
                                        <p className="font-white p18 fw-600 m-0 text">
                                          {cards[2].text[id]}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </Row>
                              </Fade>
                            </Col>
                          </Row>
                          <Row className="d-mob mb100 mt40">
                            <Fade bottom distance="20px">
                              <CarouselComponent
                                status
                                swipeable
                                responsive={responsiveSpeaker}
                                autoPlay={false}
                              >
                                {cards[2].text.map((item, id) => {
                                  return (
                                    <div className="card-wrapper">
                                      <div className="img-wrapper mb30">
                                        <img
                                          src={cards[2].image[id].url}
                                          className="w-100"
                                        />
                                      </div>
                                      <p className="font-white p18 fw-600 m-0 text">
                                        {cards[2].text[id]}
                                      </p>
                                    </div>
                                  );
                                })}
                              </CarouselComponent>
                            </Fade>
                          </Row>
                        </Row>
                      </Container>
                    </section>

                    <section className="single-box mt50">
                      <Container className="Layout-container-976">
                        <Row className="content mx-auto">
                          <Col lg={4}>
                            <Fade bottom distance="20px">
                              <p className="heading font-roboto font-white m-0">
                                {cards[3].cardName}
                              </p>
                            </Fade>
                          </Col>
                          <Col lg={8}>
                            <Fade bottom distance="20px">
                              <p className="description m-0 p16">
                                {cards[3].cardDescription}
                              </p>
                            </Fade>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="double-box mt24">
                      <Container className="Layout-container-976">
                        <div className="d-flex justify-content-between flex-wrap gap-24">
                          <Fade bottom distance="20px">
                            <div className="box-1 box">
                              <p className="heading font-roboto font-white m-0 mb30">
                                {cards[4].cardName}
                              </p>
                              <ul>
                                {cards[4].text.map((item) => {
                                  return (
                                    <li className="description">{item}</li>
                                  );
                                })}
                              </ul>
                            </div>
                          </Fade>
                          <Fade bottom distance="20px">
                            <div className="box-2 box">
                              <p className="heading font-roboto font-white m-0 mb30">
                                {cards[5].cardName}
                              </p>
                              <ul>
                                {cards[5].text.map((item) => {
                                  return (
                                    <li className="description">{item}</li>
                                  );
                                })}
                              </ul>
                            </div>
                          </Fade>
                        </div>
                      </Container>
                    </section>

                    <section className="CTA-section">
                      <Container className="Layout-container-976">
                        <Row className="register-box justify-content-center">
                          <Col lg={6}>
                            <Fade bottom distance="20px">
                              <p className="cta-text font-roboto">
                                We’ll see you there!
                              </p>
                            </Fade>
                          </Col>

                          <Col lg={4}>
                            <Fade bottom distance="20px">
                              <Buttons
                                theme="primary pink w-100"
                                text="REGISTER NOW FOR FREE"
                                onClick={() => setPopUp(true)}
                              />
                            </Fade>
                          </Col>
                        </Row>
                      </Container>
                      <div id="3"></div>
                    </section>

                    <section className="Faq-section">
                      <Container>
                        <div className="faq">
                          <div className="outer mx-auto">
                            <Fade bottom distance="20px">
                              <h2 className="faq-heading text-left font-roboto">
                                We are sure you have questions
                              </h2>
                            </Fade>
                            <CustomAccordion items={faqs} />
                          </div>
                        </div>
                      </Container>
                    </section>
                  </Frame>
                </div>
              );
            })}
          </>
        )}
      />
    </div>
  );
};

export default SuperSummitUSA;
